
























































































































































































































































































































































































































































































































import BaseComponent from '@/core/base.component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'Agreement',
})
export default class Agreement extends BaseComponent {}
